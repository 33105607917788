<template>
    <v-content>
        <v-breadcrumbs :items="breadcrumbs" large></v-breadcrumbs>
        <v-row>
            <v-col
                    cols="2"
                    v-for="(item, index) in modules"
                    v-bind:key="index"
                    class="viewCard"
            >
                <router-link :to="item.link">
                    <HoverCard :cardData="item"></HoverCard>
                </router-link>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "AcademicReportDashboard",
        watch: {},
        components: {
            HoverCard
        },
        computed: {
            modules() {
                return [
                    {
                        name: this.$t('yearly_report'),
                        type: "monthlyYearlyReport",
                        link: "/reportModules/reportAcademic/monthly-yearly",
                        icon: "mdi-chart-areaspline"
                    },
                    {
                        name: this.$t('public_private_institute_report'),
                        type: "PPInstituteReport",
                        link: "/reportModules/reportAcademic/private-public-instituteReport",
                        icon: "mdi-chart-pie"
                    },
                    ]
            },
            breadcrumbs() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.academic'),
                        disabled: true,
                        exact: true
                    }
                    ]
            }
            },
        mounted() {
        },
        methods: {

        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>